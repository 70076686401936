/** Ionic CSS Variables **/
:root {
  background-color: #F8F5F7;
  /** primary **/
  --ion-color-primary: #353434;
  --ion-color-primary-rgb: 53, 52, 52;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #353434;
  --ion-color-primary-tint: #353434;
  /** secondary **/
  --ion-color-secondary: #5f6c7b;
  --ion-color-secondary-rgb: 95, 108, 123 ;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5f6c7b;
  --ion-color-secondary-tint: #5f6c7b;
  /** tertiary **/
  --ion-color-tertiary: #9dbed6;
  --ion-color-tertiary-rgb: 216, 238, 254;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #9dbed6;
  --ion-color-tertiary-tint: #9dbed6;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #F69E1C;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #F69E1C;
  --ion-color-warning-tint: #F69E1C;
  /** danger **/
  --ion-color-danger: #bc2d45;
  --ion-color-danger-rgb: 188, 45, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #BC2D45;
  --ion-color-danger-tint: #BC2D45;
  /** dark **/
  --ion-color-dark: #323439;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #383a3e;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #6E6F73;
  --ion-color-medium-rgb: 110, 111, 115;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6E6F73;
  --ion-color-medium-tint: #6E6F73;
  /** light **/
  --ion-color-light: #f7e0d2;
  --ion-color-light-rgb: 247, 224, 210;
  --ion-color-light-contrast: #231f1f;
  --ion-color-light-contrast-rgb: 35, 31, 31;
  --ion-color-light-shade: #F7E0D2;
  --ion-color-light-tint: #F7E0D2;
}